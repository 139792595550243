import React, {FC, useEffect, useState} from 'react'
import ExamPageCard from '../components/examComponents/examPageCard/ExamPageCard.component'
import {useHistory, useParams} from 'react-router'
import {useMutation, useQuery} from '@apollo/client'
import {useForm, FormProvider} from "react-hook-form";
import {Box, Button, CircularProgress, Fade, Typography} from "@material-ui/core";
import {scrollToElementId, scrollToTop} from "../utils/scrollTo";
import ExamQuestionCard from "../components/examComponents/examQuestionCard/ExamQuestionCard.component";
import {EXAM_QUESTIONS} from "../components/examComponents/examUtils/examQuestions.utils";
import {FINISH_EXAM} from "../components/examComponents/examQuestionCard/ExamQuestionCard.mutation";
import {
  ANONYMOUS_EXAM, 
  // ANSWERS_LIST
} from "../components/examComponents/examQuestionCard/ExamQuestionCard.query";
import LoaderBox from "../components/common/loaderBox/LoaderBox.component";
import {useExamQuestionCardStyles} from "../components/examComponents/examQuestionCard/ExamQuestionCard.styles";
import {isSavingAnswers} from "../store/savingExamAnswer/savingExamAnswer.selectors";
import {useSelector} from "react-redux";
import GlobalSuccessSnackbar from "../components/formItems/globalSuccessSnackbar/GlobalSuccessSnackbar";
import ExamProgressBar from "../components/examComponents/examProgressBar/ExamProgressBar.component";
import ExamAdditionalSection from '../components/examComponents/examAdditionalSection/ExamAdditionalSection';
import StartExam from '../components/examComponents/startExam/StartExam';
import { AnonymousUser } from '../store/user/user.types';
import GeneratePdfResultModal from '../components/examComponents/generatePdfResultModal/GeneratePdfResultModal';

const ExamPage: FC = () => {
  const [startedExam, setStartedExam] = useState(false)
  const [openFinishModal,setOpenFinishModal] = useState(false)
  const [currentSection, setCurrentSection] = useState(0)
  const [isSuccessSaved, setIsSuccessSaved] = useState<boolean>(false)
  const examSectionsStartQuestionIndex: number[] = [0,11,23]
  const classes = useExamQuestionCardStyles()
  const {push} = useHistory()

  const isSavingAnswer = useSelector(isSavingAnswers)

  const form = useForm()
  const { id } = useParams<{ id: string }>()

  const [finishExam, {loading: finishExamLoading, data: mutationData}] = useMutation<{finishExam: {string: string}}>(FINISH_EXAM)
  const {data, loading} = useQuery<AnonymousUser | null>(ANONYMOUS_EXAM, {
    variables: {
      examId: id
    }
  })

  const scrollToFirstError = () => {
    const {errors} = form.formState
    const arrayFromErrors = Object.values(errors)
    if (arrayFromErrors.length) {
      scrollToElementId(arrayFromErrors[0].ref.name, -150)
    }
  }

  const handleSubmit = form.handleSubmit(async() => {
    setIsSuccessSaved(false)

    try {
      await finishExam({
        variables: {
          examId: id
        }
      })
      setIsSuccessSaved(true)

      setOpenFinishModal(true)
    } catch (e){
      console.error(e)
    }
  }, async() => {
    scrollToFirstError()
  })

  const triggerErrorsOnNextPageClick = async() => {
    const isFormValidate = await form.trigger()

    if (isFormValidate) {
      setCurrentSection(sectionNumber => sectionNumber + 1)
      scrollToTop()
    } else {
      scrollToFirstError()
    }
  }

  const startExam = () => setStartedExam(true)
  const closeFinishModal = () => setOpenFinishModal(false)

  const loadingBox = (text: string) => (
    <div className={classes.loaderBox}>
      <CircularProgress size={12} thickness={7} color="inherit"/>

      <Typography className={classes.loaderText} variant={"body2"}>
        { text }
      </Typography>
    </div>
  )
  
  useEffect(() => {
    if(!loading && !data) {
      push("/404")
    }
  },[loading])

  if(loading) return <LoaderBox />
  return (
    <ExamPageCard
      fullName={data?.anonymousExamUserData || ""}
      exam
    >
      {!startedExam
        ? <StartExam id={id} startExam={startExam}/>
        : <FormProvider {...form}>
            <form onSubmit={handleSubmit} className={finishExamLoading ? classes.disabledForm: ""}>
              <ExamProgressBar
                currentSectionNumber={currentSection + 1}
                sectionsAmount={examSectionsStartQuestionIndex.length}
              />

              {EXAM_QUESTIONS
                .slice(
                  examSectionsStartQuestionIndex[currentSection],
                  currentSection === examSectionsStartQuestionIndex.length -1
                    ? EXAM_QUESTIONS.length
                    : examSectionsStartQuestionIndex[currentSection + 1]
                )
                .map(question => (
                  <ExamQuestionCard
                    key={question.id}
                    currentStep={currentSection}
                    examId={id}
                    {...question}
                  />
              ))}
              
              {currentSection === examSectionsStartQuestionIndex.length -1 && (
                <ExamAdditionalSection examId={id}/>
              )}

              <Fade in={isSavingAnswer || finishExamLoading} timeout={300}>
                <Box className={classes.loaderBoxWrapper}>
                  { isSavingAnswer && loadingBox("Trwa zapisywanie odpowiedzi")}
                  { finishExamLoading && loadingBox("Trwa zapisywanie badania")}
                  {" "}
                </Box>
              </Fade>

              <Box display={"flex"} justifyContent={currentSection === 0 ? "flex-end" : "space-between"} width={"100%"}>
                {currentSection !== 0 &&
                  <Button
                    type="button"
                    variant={"outlined"}
                    color={"primary"}
                    disabled={isSavingAnswer}
                    onClick={() => setCurrentSection(sectionNumber => sectionNumber - 1)}
                  >
                    Poprzednia strona
                  </Button>
                }

                <div>
                  {currentSection !== examSectionsStartQuestionIndex.length - 1 &&
                    <Button
                      type="button"
                      variant={"contained"}
                      color={"primary"}
                      disabled={isSavingAnswer}
                      className={classes.nextPageButton}
                      onClick={triggerErrorsOnNextPageClick}
                    >
                      Następna strona
                    </Button>
                  }
                </div>

                {currentSection === examSectionsStartQuestionIndex.length -1 &&
                  <Button
                    type="submit"
                    variant={"contained"}
                    color={"primary"}
                    disabled={finishExamLoading || isSavingAnswer}
                  >
                    Zakończ badanie
                  </Button>
                }

              </Box>
            </form>
          </FormProvider>
        }
        <GlobalSuccessSnackbar
          isSuccess={isSuccessSaved}
          successMessage={"Zapisano badanie"}
        />
      <GeneratePdfResultModal 
        open={openFinishModal}
        closeModal={closeFinishModal}
        token={mutationData?.finishExam?.string}
      />
    </ExamPageCard>
  )
}

export default ExamPage