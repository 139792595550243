import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react'
import { useExamResultsTableStyles } from '../examResultsTable/ExamResultsTable.styles';
import { employeeRecommendations } from '../examUtils/recommendationsForEmployee.utils';

interface ExamDetailedAssessmentProps {
  code: string,
  title: string,
  color: "red" | "yellow" | "green"
}

const ExamDetailedAssessment: FC<ExamDetailedAssessmentProps> = ({ code, title, color }) => {
  const classes = useExamResultsTableStyles()
  const recommendation = employeeRecommendations.find(item=> item.code === "B130")
  return (
    <div className='no-break-inside'>
      <Typography variant='h5'>
        { code } { title }
      </Typography>
      <div style={{display: "flex", marginBottom: 12}}>
        <Typography style={{marginRight: 12}}>Wynik:</Typography>
        <div className={classes.circleWrapper}>
          <div className={clsx(classes.circle, classes[color], "print-circle")}/>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{__html: recommendation?.colors[color] ?? ""}} />
      <div style={{margin: "16px 0"}}>
        <Divider/>
      </div>
    </div>
  )
}

export default ExamDetailedAssessment