import {FC, useEffect, useState} from 'react'
import ExamPageCard from "../components/examComponents/examPageCard/ExamPageCard.component";
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component";
import {RouteComponentProps, useParams} from "react-router";
import { Box, Typography} from "@material-ui/core";
import ExamResultsTable from "../components/examComponents/examResultsTable/ExamResultsTable.component";
import ProjectDataLogos from '../components/common/projectDataLogos/ProjectDataLogos.component';
import clientFetch from '../fetch';
import ExamResultGeneralDescription from '../components/examComponents/examResultGeneralDescription/ExamResultGeneralDescription.component';
import { EXAM_QUESTIONS } from '../components/examComponents/examUtils/examQuestions.utils';
import ExamDetailedAssessment from '../components/examComponents/examDetailedAssessment/ExamDetailedAssessment';
import ExamAdditionalSourcesOfInformation from '../components/examComponents/examAdditionalSourcesOfInformation/ExamAdditionalSourcesOfInformation';
import { activityOptions, privateOptions } from '../components/examComponents/examAdditionalSection/ExamAdditionalSection';
import CheckIcon from '@material-ui/icons/Check';

export interface ExamResultPageProps {
  token: string
}

export type ResultData = {
  examinedPersonId: string;
  yellow: string[],
  red: string[],
  green: string[],
  createdAt: string;
  factors: {
    answers: string[],
    factor: string
  }[],
}

const ExamResultPage: FC<RouteComponentProps<ExamResultPageProps>> = () => {
  const {token} = useParams<ExamResultPageProps>()
  const [data, setData] = useState<ResultData>()
  const [colors, setColors] = useState<Array<{code: string, color: "red" | "green" | "yellow"}>>()
  const [loading, isLoading] = useState(true)

  useEffect(() => {
    const fetchData = async() => {
      clientFetch(`/api/single-result/${token}`,{method: "GET"})
        .then(reponse => reponse.json())
        .then((values: ResultData[])=> {
          setData(values[0])
          const convertedColors = [...(values[0]?.green || []).map(item=> ({code: item, color: "green" as "green"})), ...(values[0]?.red || []).map(item=> ({code: item, color: "red" as "red"})), ...(values[0]?.yellow || []).map(item=> ({code: item, color: "yellow" as "yellow"}))]
          setColors(convertedColors)
          isLoading(false)
        })
    }
    fetchData()
  },[])

  const arrayWithColors = EXAM_QUESTIONS.map(examItem => ({
    code: examItem.code,
    title: examItem.title,
    color: colors?.find(item => item.code === examItem.code)?.color
  }))

  if(loading) return <p>loading</p>

  return (
    <AdminPageCard title={ "Karta wyników dla pracownika" }>
      <ExamPageCard fullName={data!.examinedPersonId}>
        <div id="pageIsReadyForPrinting"/>
        <Box mb={3}>
          <Typography align={"center"} variant={"h4"}>
            Data: {data?.createdAt.split("T")[0]}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography align={"center"} variant={"h4"}>
            Ocena zdolności do pracy w oparciu o ICF
          </Typography>
        </Box>

        <Typography align={"center"}>
          Poniżej przedstawiono wyniki samooceny Pani/Pana zdrowia i funkcjonowania w oparciu wybrane kategorie ICF (Międzynarodowej Klasyfikacji Funkcjonowania, Niepełnosprawności i Zdrowia). Wynik dotyczący każdej z funkcji oznaczono kolorami. Kolor zielony oznacza wynik doskonały, kolor żółty – wynik dobry, kolor czerwony – wynik wskazujący na konieczność podjęcia działań wspierających.
        </Typography>

        <ExamResultGeneralDescription data={data!}/>

        <ExamResultsTable data={arrayWithColors!}/>

        <Typography variant={"h5"} component={"p"} className='print-page-break-before'>
          Ocena szczegółowa
        </Typography>
        
        {arrayWithColors.map(item => (
          <ExamDetailedAssessment code={item.code} title={item.title} color={item.color as "red" | "green" | "yellow"}/>
        ))}

        <Typography variant='h5'>
          Chęć kontynuacji pracy w aktualnym przedsiębiorstwie po przekroczeniu wieku emerytalnego: {data?.factors.find(item => item.factor === "factor_19")?.answers[0]}
        </Typography>

        <Typography variant='h5'>
          Zadowolenie z działań pracodawcy na rzecz przedłużenia aktywności zawodowej pracowników po przekroczeniu wieku emerytalnego: {data?.factors.find(item => item.factor === "factor_20")?.answers[0]}
        </Typography>

        <Box my={4} className='no-break-inside'>
          <Typography variant='h5'>
          Czynniki związane z pracą:
        </Typography>

        {activityOptions.map(item => (
          <Typography>
            {item.value}. {item.label} - {data?.factors.find(factor => factor.factor === `factor_${item.value}`)?.answers[0] === "true" && (< CheckIcon/>)}
          </Typography>
        ))}
        </Box>

        <Box my={4} className='no-break-inside'>
          <Typography variant='h5'>
            Czynniki związane z życiem prywatnym:
          </Typography>

          {privateOptions.map((item, index)=> (
            <Typography>
              {index + 1}. {item.label} - {data?.factors.find(factor => factor.factor === `factor_${index + 14}`)?.answers[0] === "true" && (< CheckIcon/>)}
            </Typography>
          ))}
        </Box>
        
        <ExamAdditionalSourcesOfInformation/>
      </ExamPageCard>
      <ProjectDataLogos/>
    </AdminPageCard>
  )
}

export default ExamResultPage